<template>
<div>
    <loading v-if="loading" />
    <v-container v-if="!loading">
        <v-card outlined>
            <v-container>
                <v-row align="center" justify="center" class="mx-4">
                    <v-col>
                        <v-avatar tile size='150' class="mt-1">
                            <v-img contain :src="currentItem.merchant.platform == null ? $config.logo : currentItem.merchant.platform.logoUrl"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col class="text-center">
                        <!-- <v-chip label  color="primary"  style="margin-top: -120px">
                          <span class="px-3"> test</span>
                        </v-chip> -->
                        <v-chip style="margin-top: -120px" label :color="currentItem.status.color" dark>
                            <h4>{{currentItem.status.arabicName}}</h4>
                        </v-chip>
                        <v-row dir="ltr" justify="center" align="center">
                            <qr-code :size="80" :text="currentItem.no"></qr-code>
                        </v-row>
                    </v-col>
                    <v-col class="text-end">
                        <h3>
                            <strong>
                                رقم الفاتورة
                            </strong>
                        </h3>
                        <h3>{{ currentItem.no }}</h3>
                        <span class="grey--text">{{ currentItem.created.substr(0,10) }}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                    </v-col>
                    <v-col>
                        <h3>
                            <strong>
                                الزبون:
                            </strong>
                            <h3>
                                {{ currentItem.customerName }}
                                <br />
                                <span style="font-size: 16px">
                                    {{ currentItem.deliveryAddress }}
                                    ({{ currentItem.deliveryGovernorate.name }} - <span v-if="currentItem.deliveryArea">{{ currentItem.deliveryArea.name }}</span>)
                                </span>
                                <br />
                                <span class="grey--text" dir="ltr" style="font-size: 16px">
                                    {{ currentItem.customerPhoneNumber }}
                                    <br />
                                    {{ currentItem.customerPhoneNumber2 }}
                                </span>

                            </h3>
                        </h3>
                    </v-col>
                    <v-col>
                        <h3>
                            <strong>
                                التاجر:
                            </strong>
                            <h3>
                                {{ currentItem.merchant.fullName }}
                                <br />
                                <span style="font-size: 16px">
                                    {{ currentItem.merchant.address }}
                                    ({{ currentItem.merchant.governorate.name }} - {{ currentItem.merchant.area.name }})
                                </span>
                                <br />
                                <span class="grey--text" dir="ltr" style="font-size: 16px">
                                    {{ currentItem.merchant.phoneNumber }}
                                </span>
                            </h3>
                        </h3>
                    </v-col>
                    <v-col>
                        <h3>
                            <strong>
                                المخزن / المندوب:
                            </strong>
                            <h3>
                                {{ currentItem.warehouse.name }}
                                <br />
                                <span class="grey--text" dir="ltr" style="font-size: 16px" v-if="currentItem.delegater">
                                    {{ currentItem.delegater.phoneNumber }}
                                </span>
                            </h3>
                        </h3>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                    </v-col>
                    <v-col cols="12">
                        <!-- قيمة الشحنة -->
                        <table style="width:40%">
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            قيمة الشحنة:
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.packageValue)}} IQD
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            اجور التوصيل ( للمندوب )
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.deliveryCost)}} IQD
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            اجور المكتب ( الاتفاق مع التاجر )
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.officeFees)}} IQD
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            الحجم الزائد
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.sizeCost)}} ( {{ currentItem.extraSize }} ) IQD
                                    </strong>
                                </td>
                            </tr>
                            <tr class="text--center">
                                <v-divider class="my-2 mx-auto " />
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            تكلفة الشحنة
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.packageCost)}} IQD
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>
                                        <strong>
                                            المبلغ المستلم
                                        </strong>
                                    </h4>
                                </td>
                                <td>
                                    <strong style="font-size: 16px">
                                        {{$service.formatCurrency(currentItem.receivedAmount)}} IQD
                                    </strong>
                                </td>
                            </tr>
                        </table>
                    </v-col>

                    <v-col>
                        <v-divider />
                    </v-col>

                    <v-col cols="12">
                        <v-textarea v-model="comment" placeholder="اكتب تعليقك هنا...." outlined filled />
                        <v-btn @click="postComment()" :disabled="comment == ''" :loading="uploading" x-large class="btn" color="primary">حفظ التعليق</v-btn>
                    </v-col>

                    <v-col cols="12" v-for="(comment, index) in currentItem.comments" :key="index">
                        <v-card outlined>
                            <v-container>
                                <v-row class="ma-2">
                                    <v-avatar size="35" class="ml-2" color="grey lighten-3">
                                        {{ comment.creator.fullName.substr(0,2) }}
                                    </v-avatar>
                                    <strong>{{ comment.creator.fullName }}
                                    </strong>
                                    <v-spacer />
                                    <span style="font-size: 14px" class="grey--text">{{ comment.created.substr(0,10) }} / {{formatDate(comment.created)}}</span>
                                </v-row>
                                <span class="mr-12">
                                    {{ comment.content }}
                                </span>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- <pre dir="ltr">
                {{ currentItem }}
                </pre> -->
            </v-container>
        </v-card>
    </v-container>
</div>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            currentItem: {},
            loading: false,
            uploading: false,
            comment: ""
        }
    },

    created() {
        this.getItems()
        // console.log(moment().format('LTS'))
    },

    methods: {
      formatDate(date) {
        return moment(date).format('h:mm:ss a'); // 'LL' is a format string; you can customize it
      },  
        // convertTo12HourFormat(time24) {
        //     const [hours, minutes, seconds] = time24.split(':').map(Number);

        //     // Extract hours and minutes from the 24-hour format
        //     const hours24 = hours;
        //     const minutes24 = minutes;

        //     // Calculate hours for 12-hour format, adjusting for 0-23 range
        //     let hours12 = hours24 % 12 || 12; // Handle 0 hours as 12

        //     // Determine AM/PM period based on original hours (not adjusted hours12)
        //     const period = hours24 >= 12 ? 'مساء' : 'صباحا';

        //     // Construct the 12-hour time string
        //     const time12 = `${hours12.toString().padStart(2, '0')}:${minutes24.toString().padStart(2, '0')} ${period}`;

        //     return time12;
        // },

        // convertTo12HourFormat(time){

        // },

        async postComment() {
            this.uploading = true
            let data = {
                content: this.comment,
                orderId: this.$route.params.id
            }
            try {
                await this.$http.post(`Comment`, data)
                this.comment = ""
                let res = await this.$http.get(`Comment/${this.$route.params.id}`)
                this.currentItem.comments = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.uploading = false
            }
        },
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Order/${this.$route.params.id}`)
                this.currentItem = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        }
    },

}
</script>
